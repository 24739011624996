import { globalHistory } from '@reach/router'

export const onRouteUpdate = () => {
  globalHistory.listen(args => {
    args.location.action = args.action
  })

  if (window.location.hostname !== 'localhost') {
    let growScript = document.getElementById('growScript');
    if (growScript) {
      growScript.remove();
    }

    // Define the new script loading function using an IIFE pattern
    (function initializeGrowScript() {
      // Check if growMe is already initialized
      if (!window.growMe) {
        // Initialize growMe function to queue commands
        window.growMe = function(e) {
          window.growMe._.push(e);
        };
        // Initialize the command queue
        window.growMe._ = [];
      }

      // Create the script element
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = 'https://faves.grow.me/main.js';
      scriptElement.defer = true;
      scriptElement.setAttribute('data-grow-faves-site-id', 'U2l0ZTplYWM1NDk2Ni01ODVhLTQ4ZjYtOTk5OC1iYTYyMTA1ZTQ2ODg=');
      scriptElement.id = 'growScript'; // Assign an ID for potential future reference or removal

      // Insert the script before the first script tag in the document
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scriptElement, firstScriptTag);
    })();
  }
}
